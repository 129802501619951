import { Outlet } from 'react-router-dom';
import { Col, Row } from 'antd';
import useWindowDimensions from '../../hooks/dimensions.hook';

const AuthLayout = () => {
const { width } = useWindowDimensions();
const showMiro = window.location.hostname == 'app.itdraft.mx'? true : false;
const urlLogo = 'https://storage.googleapis.com/itdraft/logo-miru.png';
  return (
    <>
      <Row>
        <Col span={width <= 767 ? 24 : 12}>
          <div className="auth-layout__identity">
            <div className="auth-layout__identity__container">
              {width <= 767 ? (
                <img className="header__logo" src={showMiro? '/logo-small.png': urlLogo} alt="IT DRAFT LOGIN" />
              ) : (
                <img className="auth-layout__identity__logo" src={showMiro? '/logo.png': urlLogo} alt="IT DRAFT LOGIN" />
              )}
            </div>
          </div>
        </Col>
        <Col span={width <= 767 ? 24 : 12}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default AuthLayout;

export const ACCESS_ROUTES = [
  '/auth'
];

export const UNPROTECTED_ROUTES = [
  '/document',
  '/auth'
];

export const APP_URLS = {
  LOGIN: '/',
  DASHBOARD: '/admin',
  REPORTS: '/admin/reports',
  COMPANIES: '/admin/companies',
  ADMINS: '/admin/admins',
  TEMPLATES: '/admin/templates',
  DOCUMENTS: '/admin/documents',
  DOCUMENT_CREATOR: '/admin/document/creator',
  PROFILE: '/admin/profile',
};

export const STATUS_DOCUMENT = [
  {
    slug: 'COMPLETADO',
    color: '#4CAF50',
  },
  {
    slug: 'PENDIENTE',
    color: '#FB8C00',
  }
];

export const STATUS_VOBO = [
  {
    slug: 'ACEPTADO',
    color: '#4CAF50',
  },
  {
    slug: 'PENDIENTE',
    color: '#FB8C00',
  },
  {
    slug: 'RECHAZADO',
    color: 'red',
  }
];

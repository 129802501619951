const actions = {
  GET_DATA_DOCUMENTS: 'GET_DATA_DOCUMENTS',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  SET_DATA_DOCUMENTS: 'SET_DATA_DOCUMENTS',
  SET_DATA_NEW_DOCUMENT: 'SET_DATA_NEW_DOCUMENT',
  getDocumentsData: () => {
    return {
      type: actions.GET_DATA_DOCUMENTS,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDocumentsData: (data: any) => {
    return {
      type: actions.SET_DATA_DOCUMENTS,
      data,
    };
  },
  setNewDocumentData: (data: any) => {
    return {
      type: actions.SET_DATA_NEW_DOCUMENT,
      data,
    };
  },
};

export default actions;

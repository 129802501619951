import Cookies from 'js-cookie';
import actions from './actions';
import { removeItem, setItem, getItem } from '../../utils/localStorageControl';
import { DataService } from '../../services/http-interceptor.service';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values:any) => {
  return async (dispatch: (arg0: { type: string; data?: any; err?: any; }) => void) => {
    try {
      dispatch(loginBegin());
      const response = await DataService.post('auth/login', values);
      if (response.status === 200) {
          await Cookies.set('logedIn', 'true');
          setItem('access_token',response.data.tokens?.access?.token)
          setItem('refresh_token',response.data.tokens?.refresh?.token)
          setItem('user',response.data.user)
          return dispatch(loginSuccess(true));
      }else{
          await Cookies.set('logedIn', 'false');
          return dispatch(loginErr(response.data))
      }

    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const loginToken = (response:any) => {
  return async (dispatch: (arg0: { type: string; data?: any; err?: any; }) => void) => {
    try {
      dispatch(loginBegin());
      if (response.status === 200) {
          await Cookies.set('logedIn', 'true');
          setItem('access_token',response.data.tokens?.access?.token)
          setItem('refresh_token',response.data.tokens?.refresh?.token)
          setItem('user',response.data.user)
          return dispatch(loginSuccess(true));
      }else{
          await Cookies.set('logedIn', 'false');
          return dispatch(loginErr(response.data))
      }

    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch: (arg0: { type: string; data?: any; err?: any; }) => void) => {
    try {
      dispatch(logoutBegin());
      await DataService.post('auth/logout', {
        refreshToken: getItem('refresh_token')
      });
      await Cookies.remove('logedIn');
      removeItem('access_token');
      removeItem('refresh_token');
      removeItem('user');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, loginToken };

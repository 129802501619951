const actions = {
  GET_DATA_USER: 'GET_DATA_USER',
  SET_DATA_ADMIN: 'SET_DATA_ADMIN',
  EDIT_DATA_USER: 'EDIT_DATA_USER',
  DELETE_DATA_USER: 'DELETE_DATA_USER',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  //NEW
  SET_NEW_DATA_USER: 'SET_NEW_DATA_USER',
  getUserData: () => {
    return {
      type: actions.GET_DATA_USER,
    };
  },
  setAdminData: (data:any) => {
    return {
      type: actions.SET_DATA_ADMIN,
      data,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setDeleteUser: (data:any) => {
    return {
      type: actions.DELETE_DATA_USER,
      data,
    };
  },
  setEditUserData: (data:any) => {
    return {
      type: actions.EDIT_DATA_USER,
      data,
    };
  },
  setNewUserData: (data:any) => {
    return {
      type: actions.SET_NEW_DATA_USER,
      data,
    };
  },
};


export default actions;

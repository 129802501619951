import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import { branchsReducer } from './branchs/reducers';
import { userReducer } from './users/reducers';
import { adminReducer } from './userAdmin/reducers';
import { templatesReducer } from './templates/reducers';
import { documentsReducer } from './documents/reducers';

const rootReducers = combineReducers({  
  auth: authReducer,
  branchs: branchsReducer,
  users: userReducer,
  userAdmin: adminReducer,
  templates: templatesReducer,
  documents: documentsReducer,
});

export default rootReducers;

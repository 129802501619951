import actions from './actions';

const {
  GET_DATA_USER,
  SET_DATA_USER,
} = actions;

const initialState = {
  data: {},
};

const userReducer = (state = initialState, action:any) => {
  const { type, data } = action;
  switch (type) {
    case GET_DATA_USER:
      return {
        ...state
      };
    case SET_DATA_USER:
      return {
        ...state,
        data
      };
    default:
      return state;
  }
};
export { userReducer };

const Footer = () => {
  const showMiro = window.location.hostname == 'app.itdraft.mx'? true : false;
  return (
    <>
      {showMiro? (<p>IT DRAFT CLM</p>):(<p>CLM</p>)} 
      Copyright ©2023
    </>
  );
};

export default Footer;

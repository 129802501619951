import { FC } from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TeamOutlined, FileTextOutlined,
  ContainerOutlined, GoldOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Global } from '../../constants';

type Props = {
  topMenu?: Boolean,
  inlineCollapsed?: Boolean | any,
  user?: any,
}

const NavMenu: FC<Props> = ({topMenu, inlineCollapsed, user}) => {
  console.log()
  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      inlineCollapsed={inlineCollapsed}
    >
       {user?.role === 'sub' && (
        <>
          <Menu.Item icon={!topMenu && <SettingOutlined />} key="profile">
            <NavLink to={Global.APP_URLS.PROFILE}>
              Mi Cuenta
            </NavLink>
          </Menu.Item>
        </>
       )}
      {(user?.role === 'admin' || user?.role === 'sub') && (
        <>
          <Menu.Item icon={!topMenu && <TeamOutlined />} key="admins">
            <NavLink to={Global.APP_URLS.ADMINS}>
              Usuarios
            </NavLink>
          </Menu.Item>
          <Menu.Item icon={!topMenu && <ContainerOutlined />} key="document">
            <NavLink to={Global.APP_URLS.TEMPLATES}>
              Plantillas
            </NavLink>
          </Menu.Item>
        </>
      )}
      {user?.role === 'admin' && (
        <Menu.Item icon={!topMenu && <GoldOutlined />} key="branchs">
          <NavLink to={Global.APP_URLS.COMPANIES}>
            Empresas
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item icon={!topMenu && <FileTextOutlined />} key="box">
        <NavLink to={Global.APP_URLS.DOCUMENTS}>
          Documentos
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = (state:any) => ({
  user: state.users?.data || null,
});

export default connect(mapStateToProps)(NavMenu);

import { FC } from 'react';
import { Spin } from 'antd';

type Props = {
  isFullPage?: Boolean,
}


const Loader: FC<Props> = ({isFullPage}) => {
  return (
    <div className={`loader-custom ${isFullPage ? 'loader-custom--fullpage':''}`}>
      <Spin />
    </div>
  );
};

Loader.defaultProps = {
  isFullPage: false,
}

export default Loader;

import actions from "./actions";
import actionsAuth from "../authentication/actions";
import { DataService } from "../../services/http-interceptor.service";
import Cookies from "js-cookie";
import { removeItem } from "../../utils/localStorageControl";

const { setUserData } = actions;

const { logoutSuccess } = actionsAuth;

const userGetData = () => {
  return async (dispatch: (arg0: { type: string; data: any }) => void) => {
    const response = await DataService.get("users/me");
    if (response.status === 401) {
      await Cookies.remove("logedIn");
      removeItem("access_token");
      removeItem("refresh_token");
      removeItem("user");
      dispatch(logoutSuccess(false));
    } else {
      dispatch(setUserData(response.data));
    }
    try {
    } catch (err) {}
  };
};

export { userGetData };

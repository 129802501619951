import {ReactNode, Suspense} from 'react';

import Loader from '../components/atoms/loader.component';

interface SuspenseWrapperProps {
  children: ReactNode;
}

const SuspenseWrapper = (props: SuspenseWrapperProps) => {
  return (
    <Suspense
      fallback={
        <Loader isFullPage />
      }
    >
      {props.children}
    </Suspense>
  );
};

export default SuspenseWrapper;

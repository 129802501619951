import { useState, useEffect, ReactNode, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { Global } from '../constants';
import { getItem } from '../utils/localStorageControl';
import { userGetData } from '../redux/users/actionCreator';
import Loader from '../components/atoms/loader.component';

interface ProtectedRouteProps {
  children: ReactNode;
  route: string;
  userGetData: Function,
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, route, userGetData }) => {
  const navigate = useNavigate();
  const [ isProcessing, setIsProcessing ] = useState(true);
  const [ isRedirectedPage, setIsRedirectedPage ] = useState(false);
  const unprotectedRoutes = Global.UNPROTECTED_ROUTES;
  const accessRoutes = Global.ACCESS_ROUTES;

  useEffect(() => {
    validateProtectedRoute();
  }, [route]);

  const validateProtectedRoute = () => {
    const containsUnprotectedRoutes = unprotectedRoutes.filter((path) => route.startsWith(path));
    const containsAccessRoutes = accessRoutes.filter((path) => route.startsWith(path));
    const isPathProtected = route !== '/' && containsUnprotectedRoutes.length === 0;
    const isAccessRoutes = route === '/' || containsAccessRoutes.length > 0;
    const isAuthenticated = getItem('access_token');
    if (!isAuthenticated && isPathProtected) {
      setIsRedirectedPage(true);
      navigate("/"); 
      return;
    }
    if (isAuthenticated) {
      if (isAccessRoutes) {
        setIsRedirectedPage(true);
        navigate("/admin"); 
        return;
      } else {
        userGetData();
      }
    }
    setIsProcessing(false);
    setIsRedirectedPage(false);
  };

  return (
    <>
      {(isProcessing || isRedirectedPage) && <Loader isFullPage />}
      {!isProcessing && !isRedirectedPage && children}
    </>
  );
};

export default connect(null, {userGetData})(ProtectedRoute);

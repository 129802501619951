const actions = {
  GET_DATA_TEMPLATES: 'GET_DATA_TEMPLATES',
  GET_DATA_FINISH: 'GET_DATA_FINISH',
  GET_DATA_BEGIN: 'GET_DATA_BEGIN',
  SET_DATA_TEMPLATES: 'SET_DATA_TEMPLATES',
  SET_NEW_DATA_TEMPLATE: 'SET_NEW_DATA_TEMPLATE',
  EDIT_DATA_TEMPLATE: 'EDIT_DATA_TEMPLATE',
  DELETE_DATA_TEMPLATE: 'DELETE_DATA_TEMPLATE',
  getTemplatesData: () => {
    return {
      type: actions.GET_DATA_TEMPLATES,
    };
  },
  getDataBegin: () => {
    return {
      type: actions.GET_DATA_BEGIN,
    };
  },
  getDataFinish: () => {
    return {
      type: actions.GET_DATA_FINISH,
    };
  },
  setTemplatesData: (data: any) => {
    return {
      type: actions.SET_DATA_TEMPLATES,
      data,
    };
  },
  setNewTemplateData: (data: any) => {
    return {
      type: actions.SET_NEW_DATA_TEMPLATE,
      data,
    };
  },
  setEditTemplateData: (data: any) => {
    return {
      type: actions.EDIT_DATA_TEMPLATE,
      data,
    };
  },
  setDeleteTemplate: (data: any) => {
    return {
      type: actions.DELETE_DATA_TEMPLATE,
      data,
    };
  },
};

export default actions;
